import React, { memo, useEffect } from 'react';
import clsx from 'clsx';
import { Link, Outlet, useLocation } from 'react-router-dom';

import { ToastMessage } from '@components';
import styles from './Settings.module.scss';
import toastMessageType from '@constant/ToastMessage';
import { connect } from 'react-redux';
import actions from '@/application/actions';

function Settings(props) {
  const {
    actions: { getNoteRules, getNoteGroups },
  } = props;

  const location = useLocation();
  const [toastMessage, setToastMessage] = React.useState({
    isVisible: false,
    type: toastMessageType.SUCCESS,
    msg: {
      title: 'Lỗi',
      duration: 3000,
      desc: 'Chọn ngày nhỏ hơn ngày hiện tại',
    },
  });

  const [editMenu, setEditMenu] = React.useState(false);
  const toggleEditMenu = React.useCallback(() => {
    setEditMenu((editMenu) => !editMenu);
  }, []);

  const [importStockMenu, setImportStockMenu] = React.useState(false);
  const toggleImportStockMenu = React.useCallback(() => {
    setImportStockMenu((importStockMenu) => !importStockMenu);
  }, []);

  const [userMenu, setUserMenu] = React.useState(false);
  const toggleUserMenu = React.useCallback(() => {
    setUserMenu((userMenu) => !userMenu);
  }, []);

  const [ruleMenu, setRuleMenu] = React.useState(false);
  const toggleRuleMenu = React.useCallback(() => {
    setRuleMenu((ruleMenu) => !ruleMenu);
  }, []);

  useEffect(() => {
    getNoteRules();
    getNoteGroups();
  }, []);

  return (
    <div className={clsx(styles.container)}>
      <ul className={clsx(styles.navigation)}>
        <li className={clsx(styles.navigationHeader)}>Quản lí cửa hàng</li>

        <div className={styles.navigationList}>
          <Link
            className={clsx(styles.navigationItem, {
              [styles.selected]: location.pathname === '/settings',
            })}
            to="/settings"
          >
            <i className={clsx(styles.navigationItemIcon, 'fas fa-home')} />

            <span className={styles.navigationItemContent}>Trang chủ</span>
          </Link>

          <div
            className={clsx(styles.navigationItemMenu, {
              [styles.selected]:
                !editMenu && location.pathname.startsWith('/settings/edit/'),
            })}
          >
            <div
              className={clsx(styles.navigationItemHeader)}
              onClick={toggleEditMenu}
            >
              <i
                className={clsx(
                  styles.navigationItemHeaderIcon,
                  'fa-solid fa-pen-to-square',
                )}
              />

              <span className={styles.navigationItemHeaderContent}>
                Chỉnh sửa
              </span>

              <i
                className={clsx(styles.navigationItemHeaderRightIcon, {
                  ['fa-solid fa-angle-right']: !editMenu,
                  ['fa-solid fa-angle-down']: editMenu,
                })}
              />
            </div>
            {editMenu && (
              <ul className={clsx(styles.navigationItems)}>
                <Link
                  className={clsx(styles.navigationItem, {
                    [styles.selected]:
                      location.pathname === '/settings/edit/ecommerce',
                  })}
                  to={'/settings/edit/ecommerce'}
                >
                  <i
                    className={clsx(
                      styles.navigationItemIcon,
                      'fa-solid fa-square-share-nodes',
                    )}
                  />

                  <span className={styles.navigationItemContent}>
                    Trang TMĐT
                  </span>
                </Link>

                <Link
                  className={clsx(styles.navigationItem, {
                    [styles.selected]:
                      location.pathname === '/settings/edit/product',
                  })}
                  to={'/settings/edit/product'}
                >
                  <i
                    className={clsx(
                      styles.navigationItemIcon,
                      'fa-solid fa-briefcase',
                    )}
                  />

                  <span className={styles.navigationItemContent}>Sản phẩm</span>
                </Link>

                <Link
                  className={clsx(styles.navigationItem, {
                    [styles.selected]:
                      location.pathname === '/settings/edit/ship-service',
                  })}
                  to={'/settings/edit/ship-service'}
                >
                  <i
                    className={clsx(
                      styles.navigationItemIcon,
                      'fa-solid fa-truck-fast',
                    )}
                  />

                  <span className={styles.navigationItemContent}>
                    Đơn vị vận chuyển
                  </span>
                </Link>

                <Link
                  className={clsx(styles.navigationItem, {
                    [styles.selected]:
                      location.pathname === '/settings/edit/qt-product',
                  })}
                  to={'/settings/edit/qt-product'}
                >
                  <i
                    className={clsx(
                      styles.navigationItemIcon,
                      'fa-solid fa-box-archive',
                    )}
                  />

                  <span className={styles.navigationItemContent}>
                    Sản phẩm lưu kho
                  </span>
                </Link>

                <Link
                  className={clsx(styles.navigationItem, {
                    [styles.selected]:
                      location.pathname === '/settings/edit/supplier',
                  })}
                  to={'/settings/edit/supplier'}
                >
                  <i
                    className={clsx(
                      styles.navigationItemIcon,
                      'fa-solid fa-truck-fast',
                    )}
                  />

                  <span className={styles.navigationItemContent}>
                    Nhà cung cấp
                  </span>
                </Link>

                <Link
                  className={clsx(styles.navigationItem, {
                    [styles.selected]:
                      location.pathname === '/settings/edit/province',
                  })}
                  to={'/settings/edit/province'}
                >
                  <i
                    className={clsx(
                      styles.navigationItemIcon,
                      'fa-solid fa-truck-fast',
                    )}
                  />

                  <span className={styles.navigationItemContent}>
                    Tỉnh thành
                  </span>
                </Link>
              </ul>
            )}
          </div>

          <div
            className={clsx(styles.navigationItemMenu, {
              [styles.selected]:
                !importStockMenu &&
                location.pathname.startsWith('/settings/import'),
            })}
          >
            <div
              className={clsx(styles.navigationItemHeader)}
              onClick={toggleImportStockMenu}
            >
              <i
                className={clsx(
                  styles.navigationItemHeaderIcon,
                  'fa-solid fa-pen-to-square',
                )}
              />

              <span className={styles.navigationItemHeaderContent}>
                Nhập kho
              </span>

              <i
                className={clsx(styles.navigationItemHeaderRightIcon, {
                  ['fa-solid fa-angle-right']: !importStockMenu,
                  ['fa-solid fa-angle-down']: importStockMenu,
                })}
              />
            </div>

            {importStockMenu && (
              <ul className={clsx(styles.navigationItems)}>
                <Link
                  className={clsx(styles.navigationItem, {
                    [styles.selected]:
                      location.pathname === '/settings/import/create',
                  })}
                  to={'/settings/import/create'}
                >
                  <i
                    className={clsx(
                      styles.navigationItemIcon,
                      'fa-solid fa-download',
                    )}
                  />

                  <span className={styles.navigationItemContent}>Tạo đơn</span>
                </Link>

                <Link
                  className={clsx(styles.navigationItem, {
                    [styles.selected]:
                      location.pathname === '/settings/import/bill',
                  })}
                  to={'/settings/import/bill'}
                >
                  <i
                    className={clsx(
                      styles.navigationItemIcon,
                      'fa-solid fa-receipt',
                    )}
                  />

                  <span className={styles.navigationItemContent}>
                    Danh sách đơn
                  </span>
                </Link>
              </ul>
            )}
          </div>

          <div
            className={clsx(styles.navigationItemMenu, {
              [styles.selected]:
                !userMenu && location.pathname.startsWith('/settings/user'),
            })}
          >
            <div
              className={clsx(styles.navigationItemHeader)}
              onClick={toggleUserMenu}
            >
              <i
                className={clsx(
                  styles.navigationItemHeaderIcon,
                  'fa-solid fa-pen-to-square',
                )}
              />

              <span className={styles.navigationItemHeaderContent}>
                Quản lý tài khoản
              </span>

              <i
                className={clsx(styles.navigationItemHeaderRightIcon, {
                  ['fa-solid fa-angle-right']: !userMenu,
                  ['fa-solid fa-angle-down']: userMenu,
                })}
              />
            </div>

            {userMenu && (
              <ul className={clsx(styles.navigationItems)}>
                <Link
                  className={clsx(styles.navigationItem, {
                    [styles.selected]: location.pathname === '/settings/user',
                  })}
                  to={'/settings/user'}
                >
                  <i
                    className={clsx(
                      styles.navigationItemIcon,
                      'fa-solid fa-receipt',
                    )}
                  />

                  <span className={styles.navigationItemContent}>
                    Danh sách người dùng
                  </span>
                </Link>

                <Link
                  className={clsx(styles.navigationItem, {
                    [styles.selected]:
                      location.pathname === '/settings/user/create',
                  })}
                  to={'/settings/user/create'}
                >
                  <i
                    className={clsx(
                      styles.navigationItemIcon,
                      'fa-solid fa-download',
                    )}
                  />

                  <span className={styles.navigationItemContent}>
                    Tạo tài khoản
                  </span>
                </Link>
              </ul>
            )}
          </div>

          <div
            className={clsx(styles.navigationItemMenu, {
              [styles.selected]:
                !ruleMenu && location.pathname.startsWith('/settings/rule'),
            })}
          >
            <div
              className={clsx(styles.navigationItemHeader)}
              onClick={toggleRuleMenu}
            >
              <i
                className={clsx(
                  styles.navigationItemHeaderIcon,
                  'fa-solid fa-pen-to-square',
                )}
              />

              <span className={styles.navigationItemHeaderContent}>
                Nhóm quyền
              </span>

              <i
                className={clsx(styles.navigationItemHeaderRightIcon, {
                  ['fa-solid fa-angle-right']: !ruleMenu,
                  ['fa-solid fa-angle-down']: ruleMenu,
                })}
              />
            </div>

            {ruleMenu && (
              <ul className={clsx(styles.navigationItems)}>
                <Link
                  className={clsx(styles.navigationItem, {
                    [styles.selected]:
                      location.pathname === '/settings/rule/note',
                  })}
                  to={'/settings/rule/note'}
                >
                  <i
                    className={clsx(
                      styles.navigationItemIcon,
                      'fa-solid fa-receipt',
                    )}
                  />

                  <span className={styles.navigationItemContent}>
                    Danh sách nhóm quyền ghi chú
                  </span>
                </Link>

                <Link
                  className={clsx(styles.navigationItem, {
                    [styles.selected]:
                      location.pathname === '/settings/rule/permission',
                  })}
                  to={'/settings/rule/permission'}
                >
                  <i
                    className={clsx(
                      styles.navigationItemIcon,
                      'fa-solid fa-receipt',
                    )}
                  />

                  <span className={styles.navigationItemContent}>
                    Nhóm quyền chỉnh sửa
                  </span>
                </Link>
              </ul>
            )}
          </div>
        </div>
      </ul>

      <div className={clsx(styles.content)}>
        <Outlet context={{ setToastMessage }} />
      </div>

      <ToastMessage open={toastMessage} setOpen={setToastMessage} />
    </div>
  );
}

const mapStateToProps = ({ state }) => ({
  state,
});

export default connect(mapStateToProps, actions)(memo(Settings));
