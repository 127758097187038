import { ENV } from './env';

const shopId = ENV.SHOP;

const SHOP_CONFIG = {
  quoctien: {
    website: 'Khacdauquoctien.com | Quoctienvn.com',
    facebook: 'facebook.com/khacdauquoctien',
    phone: '0933339833',
    address: '198 Nguyễn Duy Cung, P12, Q.Gò Vấp, TP.HCM',
    preview: 'Cho xem và\n thử hàng',
    images: {
      logo: '/image/noibo/logo.png',
      billHeader: '/image/noibo/bill-header.jpg',
    },
    routes: {
      printRetail: '/print-retail',
    },
  },
  khada: {
    website: '/khada.vn',
    phone: '0931.103.123',
    address: '99 NGUYÊN HỒNG - P11 - BÌNH THẠNH - HCM',
    preview: 'ĐƯỢC ĐỒNG KIỂM',
    images: {
      logo: '/image/khada/logo.png',
      billHeader: '/image/khada/bill-header.jpg',
      retailHeader: '/image/khada/retail-header.png',
    },
    routes: {
      printRetail: '/khada/retail',
    },
  },
};

export const shopConfig = SHOP_CONFIG[shopId];
